import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  ListItemButton,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useDispatch, useSelector } from "react-redux";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { BoxOutline, FlashOutline, RupeeOutline, StatusOutline } from "../svg";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import WareHouseLogo from "../../assets/warehouseLogo.svg";
import { clearAssignee } from "../../app/reducers/assigneeSlice";
import { clearBrand } from "../../app/reducers/brandSlice";
import { clearGlass } from "../../app/reducers/glassSlice";
import { clearIndents } from "../../app/reducers/indentSlice";
import { clearLens } from "../../app/reducers/lensCatalogSlice";
import { clearOnboarding } from "../../app/reducers/onboardingSlice";
import { clearReturns } from "../../app/reducers/returnsSlice";
import { clearStocks } from "../../app/reducers/stockTransferSlice";
import { clearStores } from "../../app/reducers/storeSlice";
import { clearUser } from "../../app/reducers/userSlice";
import { clearOrder } from "../../app/reducers/orderSlice";
import { clearLogin } from "../../app/reducers/warehouseLoginSlice";

const DrawerComponent = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [headerOpen, setHeaderOpen] = useState({});
  const [selectedHeader, setSelectedHeader] = useState();
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");

  const userDetails = useSelector(
    (state) => state.WarehouseLoginReducer.userDetails
  );
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (token && _.isEmpty(userDetails)) {
      setUserName(localStorage.getItem("user-name"));
      setUserRole(localStorage.getItem("user-role"));
    } else {
      setUserName(userDetails?.name);
      setUserRole(userDetails?.userType);
    }
  }, [userDetails]);


  const clearReducers = async () => {
    dispatch(clearAssignee());
    dispatch(clearBrand());
    dispatch(clearGlass());
    dispatch(clearIndents());
    dispatch(clearLens());
    dispatch(clearOnboarding());
    dispatch(clearOrder());
    dispatch(clearReturns());
    dispatch(clearStocks());
    dispatch(clearStores());
    dispatch(clearUser());
    dispatch(clearLogin());
  };

  const handleLogout = async () => {
    await clearReducers();
    localStorage.removeItem("eyePlay-auth-token");
    localStorage.removeItem("eyePlay-refresh-token");
    localStorage.removeItem("user-name");
    localStorage.removeItem("user-role");
    history("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (i, name) => {
    setHeaderOpen(Object.assign({}, headerOpen, { [name]: !headerOpen[name] }));
    setSelectedHeader({
      [name]: true,
    });
  };

  const handleRoute = (i, route, name) => {
    setHeaderOpen(Object.assign({}, headerOpen, { [name]: !headerOpen[name] }));
    setSelectedHeader(name);
    if (route) {
      history(route);
    }
  };
  const sideNavbarConfig = [
    {
      icon: <BoxOutline />,
      selectedIcon: <BoxOutline color="#03a5a5" />,
      header: "Inventory",
      route: "/inventory",
      subHeader: [
        { title: "Eye Glasses", route: "/glasses" },
        { title: "Stock Transfer", route: "/stocks" },
        { title: "Onboarding", route: "/onboarding" },
      ],
    },
    {
      icon: <FlashOutline />,
      selectedIcon: <BoxOutline color="#03a5a5" />,
      header: "Indents",
      route: "/indents",
    },
    {
      icon: <StatusOutline />,
      selectedIcon: <StatusOutline color="#03a5a5" />,
      header: "Masters",
      subHeader: [
        { title: "Users", route: "/users" },
        { title: "Lens Catalog", route: "/lens-catalog" },
        // { title: "Range Lens Catalog", route: "/range-lens-catalog" },
        { title: "Brand", route: "/brand" },
      ],
    },
    {
      icon: <RupeeOutline />,
      selectedIcon: <RupeeOutline color="#03a5a5" />,
      header: "Returns",
      route: "/returns",
    },
    {
      icon: <FlashOutline />,
      selectedIcon: <BoxOutline color="#03a5a5" />,
      header: "Stores",
      route: "/stores",
    },
  ];

  const drawer = (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        {/* <img src={WareHouseLogo} /> */}
      </div>
      <Divider />
      <div style={{ cursor: "pointer" }}>
        <List
          sx={{
            width: "100%",
            bgcolor: "linear-gradient(to bottom, #242424 0%, #383838 100%)",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {sideNavbarConfig.map((val, i) => (
            <div key={i}>
              <ListItemButton
                onClick={() =>
                  _.isEmpty(val?.subHeader)
                    ? handleRoute(i, val?.route, val?.header)
                    : handleClick(i, val?.header)
                }
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  {val?.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: val?.header === selectedHeader ? "#fff" : "#a0a0a0",
                  }}
                >
                  {val?.header}
                </ListItemText>
                {val?.subHeader ? (
                  headerOpen[val?.header] ? (
                    <ExpandLess sx={{ color: "#827e7e" }} />
                  ) : (
                    <ExpandMore sx={{ color: "#827e7e" }} />
                  )
                ) : null}
              </ListItemButton>
              {val?.subHeader && (
                <Collapse in={headerOpen[val?.header]} unmountOnExit>
                  <List component="div" disablePadding>
                    {val?.subHeader.map((item, i) => (
                      <ListItemButton
                        key={i}
                        sx={{ padding: "0px 16px 8px 80px" }}
                        onClick={() => handleRoute(i, item?.route, item.title)}
                      >
                        <ListItemText
                          sx={{
                            color:
                              item?.title === selectedHeader
                                ? "#fff"
                                : "#a0a0a0",
                          }}
                        >
                          {item.title}
                        </ListItemText>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - 17%)` },
          ml: { sm: "15%" },
          backgroundColor: "#fff",
          borderBottom: "1px solid #e4e4e4",
          height: "64px",
          "&.MuiAppBar-root": {
            boxShadow: "none",
          },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ paddingRight: "15px" }}>
            {!_.isEmpty(userName) ? (
              <>
                <Typography
                  component="div"
                  sx={{ fontSize: "14px", fontWeight: 600, color: "#000" }}
                >
                  {userName}
                </Typography>
                <Typography
                  component="div"
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#827e7e" }}
                >
                  {userRole}
                </Typography>
              </>
            ) : (
              <Typography  component="div"
              sx={{ fontSize: "14px", fontWeight: 600, color: "#000" }}>Not loggedIn</Typography>
            )}
          </Box>
          <PowerSettingsNewIcon
            onClick={handleLogout}
            sx={{ fill: "#03a5a5" }}
          />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: "20%" }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "25%",
              color: "#a0a0a0",
            },
            ".MuiDrawer-paperAnchorLeft": {
              background:
                "linear-gradient(to bottom, #242424 0%, #383838 100%)",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "17%",
              color: "#a0a0a0",
            },
            ".MuiDrawer-paperAnchorLeft": {
              background:
                "linear-gradient(to bottom, #242424 0%, #383838 100%)",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default DrawerComponent;
