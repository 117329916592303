import {
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WareHouseLogo from "../../assets/warehouseLogin.svg";
import { ContinueButton } from "../../components";
import { HeadTypography } from "./styles";
import {
  login,
  userErrors,
  loginError,
  loginSuccess
} from "../../app/reducers/warehouseLoginSlice";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";
import { useIsMount } from "../../common/hooks/customHooks";

function WarehouseLogin(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const isMount = useIsMount();

  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [value, setValue] = useState({});

  const loginSuccessMessage = useSelector(
    (state) => state.WarehouseLoginReducer.loginSuccessMessage
  );
  const loginErrors = useSelector(
    (state) => state.WarehouseLoginReducer.loginErrors
  );
  const userError = useSelector(
    (state) => state.WarehouseLoginReducer.userError
  );

  const handleChange = (e, name) => {
    if (name === "phoneNumber") {
      const number = "91";
      const appendedNumber = number.concat(e.target.value);
      setValue({ ...value, [name]: appendedNumber });
    } else {
      setValue({ ...value, [name]: e.target.value });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (isMount && token) {
      history("/indents");
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (!_.isEmpty(loginSuccessMessage) && token && _.isEmpty(userError)) {
      dispatch(loginSuccess({}));
      dispatch(loginError(""));
      history("/indents");
    }
  }, [loginSuccessMessage]);

  const validation = async () => {
    let isValid = false;

    if (value.phoneNumber.length < 10) {
      isValid = false;
      setMobileError("Enter 10 digits for mobile number");
    } else if (_.isEmpty(value.password)) {
      isValid = false;
      setPasswordError("Password should not me empty");
    } else {
      isValid = true;
      setMobileError("");
      setPasswordError("");
    }

    return isValid;
  };

  const handleLogin = async () => {
    const valid = await validation();
    if (valid) {
      const obj = {
        passwordPresent: true,
        ...value,
      };
      dispatch(login(obj));
    }
  };

  const handleAlertClose = () => {
    userError ? dispatch(userErrors("")) : dispatch(loginError(""));
  };

  return (
    <>
      <Dialog open={true}>
        {loginErrors ? (
          <AlertComp
            type="error"
            message={loginErrors}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        {userError ? (
          <AlertComp
            type="error"
            message={userError}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        <DialogContent>
          <div style={{ textAlign: "center", padding: "30px" }}>
            {/* <img src={WareHouseLogo} alt={"storeLogo"} /> */}
            <HeadTypography>LOGIN INTO YOUR ACCOUNT</HeadTypography>
            <div style={{ textAlign: "initial" }}>
              <TextField
                sx={{ mb: "22px" }}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                type="text"
                placeholder="Phone number"
                helperText={mobileError}
                error={mobileError}
                variant="outlined"
                onChange={(e) => handleChange(e, "phoneNumber")}
              />

              <TextField
                required
                fullWidth
                helperText={passwordError}
                error={passwordError}
                type="password"
                placeholder="Password"
                variant="outlined"
                onChange={(e) => handleChange(e, "password")}
              />
            </div>
            <ContinueButton sx={{ mt: "22px" }} onClick={handleLogin}>
              LOGIN
            </ContinueButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default WarehouseLogin;
