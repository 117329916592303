import React, { useEffect, useState } from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  InputAdornment,
  Chip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ContinueButton,
  StyledTypography,
  BackIcon,
  ContinueShoppingButton,
} from "../../components";
import SuccessModal from "../../components/Modal/SuccessModal";
import { getGlassDropDowns } from "../../app/reducers/glassSlice";
import {
  getSaveLens,
  saveLensErrors,
} from "../../app/reducers/lensCatalogSlice";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { WithContext as ReactTags } from "react-tag-input";
import S3 from "react-aws-s3";
import "./ReactInputTags.css";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";
window.Buffer = window.Buffer || require("buffer").Buffer;

const NewCatalog = (props) => {
  const [success, setSuccess] = useState(false);

  const lensAdded = useSelector((state) => state.lensCatalogReducer.lensAdded);
  const lensAddedError = useSelector(
    (state) => state.lensCatalogReducer.lensAddedError
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGlassDropDowns());
  }, []);

  const [tags, setTags] = useState([]);
  const [error, setError] = useState({});

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
  
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const [indexRow, setIndexRow] = useState([
    {
      index: "",
      costPrice: "",
      sellPrice: "",
    },
  ]);
  const [userValue, setUserValue] = useState({});

  const lensType = ["SINGLE VISION", "BIFOCAL", "PROGRESSIVE"];
  const model = ["Standard", "Advanced", "Premium"];
  const dropDown = useSelector((state) => state.glassReducer.glassDropDown);

  const handleBack = () => {
    props.handleBack();
  };

  const handleChange = (event, value, name) => {
    if (value) {
      setUserValue({ ...userValue, [name]: value });
    } else {
      setUserValue({ ...userValue, [name]: event.target.value });
    }
  };

  const handleIndexChange = (event, value, name, index) => {
    if (value) {
      const temp = indexRow.map((row, i) => {
        if (index === i) {
          row[name] = value;
        }
        return row;
      });
      setIndexRow(temp);
    } else {
      const temp = indexRow.map((row, i) => {
        if (index === i) {
          row[name] = event.target.value;
        }
        return row;
      });
      setIndexRow(temp);
    }
  };

  const handleImageChange = (acceptedFiles) => {
    const fileWithPreview = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setUserValue({ ...userValue, thumbnailImage: fileWithPreview[0] });
  };

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);
  const random = Math.random();

  const uploadToS3 = async () => {
    if (!_.isEmpty(userValue?.thumbnailImage)) {
      return await ReactS3Client.uploadFile(
        userValue?.thumbnailImage,
        `Master/lensCatalog/${userValue?.lensType}${userValue?.lensCoating}${random}`
      );
    }

    return "";
  };

  const imageUpload = async () => {
    let obj = {};
    const data = await uploadToS3();
    Object.assign(obj, { thumbnailImage: data.location });
    return obj;
  };

  const validation = async () => {
    let isValid = false;
    if (_.isEmpty(userValue?.lensType)) {
      isValid = false;
      setError({ lensType: "lensType is Mandatory" });
    } else if (_.isEmpty(userValue?.brand)) {
      isValid = false;
      setError({ brand: "brand is Mandatory" });
    } else if (_.isEmpty(userValue?.lensCoating)) {
      isValid = false;
      setError({ lensCoating: "lensCoating is Mandatory" });
    } else if (
      userValue?.lensType === "PROGRESSIVE" &&
      _.isEmpty(userValue?.lensCategory)
    ) {
      isValid = false;
      setError({ lensCategory: "lensCategory is Mandatory" });
    } else if (
      indexRow.filter((ind) => ind?.index).length !== indexRow.length
    ) {
      isValid = false;
      setError({ ind: "index is Mandatory" });
    } else if (
      indexRow.filter((ind) => ind?.costPrice).length !== indexRow.length
    ) {
      isValid = false;
      setError({ costPrice: "costPrice is Mandatory" });
    } else if (
      indexRow.filter((ind) => ind?.sellPrice).length !== indexRow.length
    ) {
      isValid = false;
      setError({ sellPrice: "sellPrice is Mandatory" });
    } 
    // else if (_.isEmpty(userValue?.thumbnailImage)) {
    //   // isValid = false;
    //   setError({ thumbnailImage: "ThumbNailImage is Mandatory" });
    // } 
    else if (_.isEmpty(tags)) {
      isValid = false;
      setError({ tags: "tags is Mandatory" });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleUserSubmit = async () => {
    const valid = await validation();
    if (valid) {
      const data = await imageUpload();
      let tagArr = [];
      tags.map((data) => {
        tagArr.push(data?.id);
      })
      const obj = indexRow.map((row) => {
        return {
          ...userValue,
          ...row,
          ...data,
          features: tagArr,
        };
      });
      const tempObj = {
        lenses: obj,
      };
      dispatch(getSaveLens(tempObj));
    }
  };

  useEffect(() => {
    if (!_.isEmpty(lensAdded)) {
      setSuccess(true);
    }
  }, [lensAdded]);

  const handleDeleteIndex = (event, index) => {
    setIndexRow(indexRow.filter((row, i) => i !== index));
  };

  const handleAddIndex = (event, index) => {
    const addObj = {
      index: "",
      costPrice: "",
      sellPrice: "",
    };
    setIndexRow([...indexRow, addObj]);
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleSuccessClose = () => {
    props?.handleBack();
  };

  const handleAlertClose = () => {
    dispatch(saveLensErrors(""));
  };

  return (
    <>
      {lensAddedError ? (
        <AlertComp
          type="error"
          message={lensAddedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          aligntItems: "center",
        }}
      >
        <div onClick={handleBack}>
          <BackIcon />
        </div>
        <StyledTypography
          sx={{ padding: "0px 0px 0px 10px" }}
          color={"#03a5a5"}
          size={22}
          height={1.23}
          weight={600}
        >
          NEW LENS CATALOG
        </StyledTypography>
      </div>

      <Grid container>
        <Grid container>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                LENS TYPE
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={lensType}
                placeholder="Select"
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "lensType");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    error={error.lensType}
                    helperText={error.lensType}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                BRAND
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={["REGULAR"]}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "brand")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    error={error.brand}
                    helperText={error.brand}
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                COATING
              </StyledTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={dropDown?.LensCoating}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "lensCoating")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    error={error.lensCoating}
                    helperText={error.lensCoating}
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          {userValue?.lensType === "PROGRESSIVE" ? (
            <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
              <div>
                <StyledTypography
                  sx={{ padding: "20px 10px 30px 0px" }}
                  color={"#2b2b2b"}
                  size={17}
                  height={1.18}
                  weight={600}
                >
                  LENS CATEGORY
                </StyledTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="medium"
                  options={model}
                  onChange={(event, newValue) =>
                    handleChange(event, newValue, "lensCategory")
                  }
                  placeholder="Select"
                  renderInput={(params) => (
                    <TextField
                      error={error.lensCategory}
                      helperText={error.lensCategory}
                      fullWidth
                      sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                      {...params}
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {indexRow?.map((data, index) => {
          return (
            <Grid container>
              <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                <div>
                  <StyledTypography
                    sx={{ padding: "20px 10px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    INDEX
                  </StyledTypography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={["1.56", "1.6", "1.67", "1.74"]}
                    placeholder="Select"
                    value={data?.index}
                    onChange={(event, newValue) => {
                      handleIndexChange(event, newValue, "index", index);
                    }}
                    size="medium"
                    renderInput={(params) => (
                      <TextField
                        error={error.ind}
                        helperText={error.ind}
                        sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                        {...params}
                        placeholder="Select"
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                <div>
                  <StyledTypography
                    sx={{ padding: "20px 10px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    COST PRICE(&#8377;)
                  </StyledTypography>
                  <TextField
                    onChange={(event) => {
                      handleIndexChange(event, "", "costPrice", index);
                    }}
                    value={data?.costPrice}
                    fullWidth
                    error={error.costPrice}
                    helperText={error.costPrice}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                    }}
                    placeholder="0.00"
                  />
                </div>
              </Grid>
              <Grid item md={3} lg={3} sx={{ padding: "15px" }}>
                <div>
                  <StyledTypography
                    sx={{ padding: "20px 10px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={17}
                    height={1.18}
                    weight={600}
                  >
                    SELL PRICE(&#8377;)
                  </StyledTypography>
                  <TextField
                    onChange={(event) => {
                      handleIndexChange(event, "", "sellPrice", index);
                    }}
                    fullWidth
                    error={error.sellPrice}
                    helperText={error.sellPrice}
                    value={data?.sellPrice}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "25px",
                    }}
                    placeholder="0.00"
                  />
                </div>
              </Grid>
              <Grid item md={2} lg={2} sx={{ padding: "15px" }}>
                <div>
                  <StyledTypography
                    sx={{ padding: "20px 10px 30px 0px" }}
                    color={"#2b2b2b"}
                    size={12}
                    height={1.18}
                    weight={600}
                  ></StyledTypography>
                  {index !== indexRow.length - 1 ? (
                    <DeleteIcon
                      onClick={(event) => handleDeleteIndex(event, index)}
                    />
                  ) : (
                    <ContinueShoppingButton
                      onClick={(event) => handleAddIndex(event, index)}
                    >
                      Add
                    </ContinueShoppingButton>
                  )}
                </div>
              </Grid>
            </Grid>
          );
        })}
        <Grid container>
          <Grid item md={5} lg={5} sx={{ padding: "15px" }}>
            <StyledTypography
              sx={{ padding: "20px 10px 30px 0px" }}
              color={"#2b2b2b"}
              size={12}
              height={1.18}
              weight={600}
              maxFiles={1}
            >
              THUMBNAIL IMAGE
            </StyledTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange(acceptedFiles);
                }}
                name="thumbNail"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop Thumbnail image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "170px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={userValue?.thumbnailImage?.preview}
                  alt="Thumbnail"
                />
              </div>
                <div style={{ color: "#FF0000"}}>{error?.thumbnailImage}</div>
            </div>
          </Grid>
          <Grid item md={5} lg={5} sx={{ padding: "15px" }}>
            <div>
              <StyledTypography
                sx={{ padding: "20px 10px 30px 0px" }}
                color={"#2b2b2b"}
                size={17}
                height={1.18}
                weight={600}
              >
                FEATURES
              </StyledTypography>
              <div>
                <ReactTags
                  classNames={{
                    tags: "tagsClass",
                    tagInput: "tagInputClasses",
                    tagInputField: "tagInputFieldClass",
                    selected: "selectedClass",
                    tag: "tagClass",
                    remove: "removeClass",
                    suggestions: "suggestionsClass",
                    activeSuggestion: "activeSuggestionClass",
                    editTagInput: "editTagInputClass",
                    editTagInputField: "editTagInputField",
                  }}
                  tags={tags}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  inputFieldPosition="top"
                  inline={true}
                />
                <div style={{ color: "#FF0000"}}>{error?.tags}</div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div style={{ padding: "30px 0px 0px 0px" }}>
          <ContinueButton onClick={handleUserSubmit}>Submit</ContinueButton>
        </div>
      </Grid>
      <SuccessModal
        success={success}
        successHeader={"Lens added successfully"}
        button={"Go to Lens Catalog"}
        handleSuccessClose={handleSuccessClose}
      ></SuccessModal>
    </>
  );
};

export default NewCatalog;
